import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/issueIndex";
export const _frontmatter = {
  "title": "Volume 5, Issue 1"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Published: December, 2021`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./president-letter/"
        }}>{`President's Letter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./editorial/"
        }}>{`Editorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./afpi-karnataka-activities-2021/"
        }}>{`AFPI Karnataka Activities - 2021`}</a></li>
      <li parentName="ul">{`Practice experience: `}<a parentName="li" {...{
          "href": "./covid-diaries/"
        }}>{`COVID Diaries - Dr Devashish Saini`}</a></li>
      <li parentName="ul">{`Practice experience: `}<a parentName="li" {...{
          "href": "./dilemmas-of-a-gp-2/"
        }}>{`Dilemmas of a GP - 2 - Dr Swapna Bhaskar`}</a></li>
      <li parentName="ul">{`Opinion: `}<a parentName="li" {...{
          "href": "./choose-primary-health-care/"
        }}>{`Choose Primary Healthcare - Dr Pavitra Mohan`}</a></li>
      <li parentName="ul">{`Reflections: `}<a parentName="li" {...{
          "href": "./back-bencher/"
        }}>{`Back Bencher - Dr B C Rao`}</a></li>
      <li parentName="ul">{`Scientific update: `}<a parentName="li" {...{
          "href": "./essential-oils-with-proconvulsive-effects-are-physicians-and-patients-aware/"
        }}>{`Essential Oils with Proconvulsive Effects: Are Physicians and Patients Aware? - Dr Thomas Mathew`}</a></li>
      <li parentName="ul">{`Reflections: `}<a parentName="li" {...{
          "href": "./family-medicine-transforming-the-dying-art-of-listening-in-clinical-practice/"
        }}>{`Family Medicine: Transforming the Dying Art of Listening in Clinical Practice - Dr Prathamesh S Sawant`}</a></li>
      <li parentName="ul">{`Refresher: `}<a parentName="li" {...{
          "href": "./vasodilators-in-clinical-day-to-day-practice/"
        }}>{`Vasodilators in Clinical Day to Day Practice - Dr L Padma`}</a></li>
      <li parentName="ul">{`From history: `}<a parentName="li" {...{
          "href": "./an-alternative-system-of-health-care-services-j-p-naik/"
        }}>{`An Alternative System of Health Care Services - JP Naik`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./from-the-web/"
        }}>{`From the Web`}</a></li>
    </ul>
    <h3>{`Editorial Team`}</h3>
    <ul>
      <li parentName="ul">{`Dr Akshay S Dinesh`}</li>
      <li parentName="ul">{`Dr B C Rao`}</li>
      <li parentName="ul">{`Dr Ramakrishna Prasad`}</li>
      <li parentName="ul">{`Dr Roshni JhanGanguly`}</li>
      <li parentName="ul">{`Dr Sowmya B. Ramesh `}</li>
      <li parentName="ul">{`Dr Sulaiman Sharieff`}</li>
      <li parentName="ul">{`Dr Swathi S Balachandra`}</li>
    </ul>
    <p>{`Acknowledgement: Samarth Muralidhar for technical assistance in publication process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      